export const getCartoonSoundEffectUrls = () => {
  return [
      "sounds/1.mp3",
      "sounds/2.mp3",
      "sounds/3.mp3",
      "sounds/4.mp3",
      "sounds/5.mp3",
      "sounds/6.mp3",
      "sounds/7.mp3",
      "sounds/8.mp3",
      "sounds/9.mp3",
      "sounds/10.mp3",
      "sounds/11.mp3",
      "sounds/12.mp3",
      "sounds/13.mp3",
      "sounds/14.mp3",
      "sounds/15.mp3",
  ];
};

export const getKeyboardSoundEffectUrls = () => {
  return [
      "sounds/key_1.mp3",
      "sounds/key_2.mp3"
  ];
};