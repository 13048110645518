import React, { useEffect, useState } from "react";
import { Graphics, Sprite, Text, useTick } from "@saitonakamura/react-pixi";
import { Graphics as PixiGraphics } from "pixi.js";
import { sound } from '@pixi/sound';
import { Position } from "./models";
import { getCartoonSoundEffectUrls, getKeyboardSoundEffectUrls } from "./assets";

export const Char = ({
  text,
  position,
  onRemoved
}: {
  text: string;
  position: Position;
  onRemoved: () => void;
}) => {
  const [rotation, setRotation] = useState(0.0);
  const [scale, setScale] = useState(0.1);
  const [rotationDirection] = useState(Math.random() > 0.5 ? 1 : -1);
  const [alpha, setAlpha] = useState(1.0);
  const [fadeOut, setFadeOut] = useState(false);

  const [colors, setColors] = useState({
    fillColor1: "blue",
    fillColor2: "red",
    stroke: "black",
    dropShadow: "gray"
  });

  usePlayRandomSound(getKeyboardSoundEffectUrls());

  useTick(() => {
    if (Math.abs(rotation) !== 720.0) {
      setRotation((prevRotation) => prevRotation + 10 * rotationDirection);
    }

    if (!fadeOut && scale > 0) {
      setScale((prevScale) => prevScale + 0.008);
    }

    if (fadeOut && alpha > 0.0) {
      setAlpha((prevAlpha) => prevAlpha - 0.004);
    }
  });

  useEffect(() => {
    if (scale >= 1) {
      setFadeOut(true);
    }
  }, [scale, onRemoved]);

  useEffect(() => {
    if (alpha <= 0.0) {
      onRemoved();
    }
  }, [alpha, onRemoved]);

  const getRandomColor = () =>
    "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);

  useEffect(() => {
    setColors({
      fillColor1: getRandomColor(),
      fillColor2: getRandomColor(),
      stroke: getRandomColor(),
      dropShadow: getRandomColor()
    });
  }, []);

  return (
    <Text
      cacheAsBitmap={true}
      text={text}
      anchor={0.5}
      x={position.x}
      y={position.y}
      angle={rotation}
      scale={scale}
      alpha={alpha}
      style={{
        align: "center",
        fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
        fontSize: 500,
        fontWeight: "400",
        fill: [colors.fillColor1, colors.fillColor2], // gradient
        stroke: colors.stroke,
        strokeThickness: 25,
        letterSpacing: 20,
        dropShadow: true,
        dropShadowColor: colors.dropShadow,
        dropShadowBlur: 20,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 30,
        wordWrap: true,
        wordWrapWidth: 440
      }}
    />
  );
};

export const Image = ({
  position,
  onRemoved
}: {
  position: Position;
  onRemoved: () => void;
}) => {
  const getRandomImageUrl = () => {
    const images = [
      "animals/1.png",
      "animals/2.png",
      "animals/3.png",
      "animals/4.png",
      "animals/5.png",
      "animals/6.png",
      "animals/7.png",
      "animals/8.png",
      "animals/9.png"
    ];

    return images[Math.floor(Math.random() * images.length)];
  };

  const [rotation, setRotation] = useState(0.0);
  const [scale, setScale] = useState(0.1);
  const [rotationDirection] = useState(Math.random() > 0.5 ? 1 : -1);
  const [imageUrl] = useState(getRandomImageUrl());
  
  usePlayRandomSound(getCartoonSoundEffectUrls());

  useTick(() => {
    setRotation((prevRotation) => prevRotation + 0.03 * rotationDirection);
    if (scale < 0.5) setScale((prevScale) => prevScale + 0.01);
  });

  useEffect(() => {
    if (scale >= 0.5) {
      onRemoved();
    }
  }, [scale, onRemoved]);

  return (
    <Sprite
      image={`/assets/graphics/sprites/${imageUrl}`}
      anchor={0.5}
      x={position.x}
      y={position.y}
      rotation={rotation}
      scale={scale}
    />
  );
};

const usePlayRandomSound = (urls: string[]) => {
  var randomUrl = urls[Math.floor(Math.random() * urls.length)];
  const [url] = useState(randomUrl);

  useEffect(() => {
    sound.play(url);
  }, [url]);

  return url;
}

export const Cursor = ({ position }: { position: Position }) => {
  return (
    <Sprite
      image="/assets/graphics/cursor.png"
      anchor={{ x: 0.15, y: 0.11 }}
      x={position.x}
      y={position.y}
      scale={0.5}
    />
  );
};

export const CursorTail = ({positions }: { positions: Position[] }) => {
  const draw = React.useCallback(
    (g: PixiGraphics) => {
      g.clear();

      if (positions.length === 0)
        return;

      const alphaByIndex = (index: number) => index / positions.length;

      const drawCircleAt = (position: Position, index: number) => {
        g.lineStyle(alphaByIndex(index), 0xAAAAAA);
        g.beginFill(0xFFFFFF, alphaByIndex(index));
        g.drawCircle(position.x, position.y, 10);
        g.endFill();
      };

      drawCircleAt(positions[0], 0);

      if (positions.length > 1) {
        for (let index = 1; index < positions.length; index++) {
          const fromPosition = positions[index - 1];
          const toPosition = positions[index];

          g.position.set();
          g.lineStyle({ color: 0xFFFFFF, width: 1 })
            .moveTo(fromPosition.x, fromPosition.y)
            .lineTo(toPosition.x, toPosition.y);

          drawCircleAt(toPosition, index);
        }
      }
    },
    [positions]
  );

  return (
    <Graphics
      draw={draw}
      anchor={0}
      x={0}
      y={0}
    />
  );
};

export const Shape = ({
  position,
  onRemoved
}: {
  position: Position;
  onRemoved: () => void;
}) => {
  const [rotation, setRotation] = useState(0.0);
  const [scale, setScale] = useState(2.0);
  const [rotationDirection] = useState(Math.random() > 0.5 ? 1 : -1);
  const [shapeType] = useState(Math.floor(Math.random() * 6));

  const [colors, setColors] = useState({
    fillColor1: 0x0000ff,
    stroke: 0x000000
  });

  useTick(() => {
    setRotation((prevRotation) => prevRotation + 0.03 * rotationDirection);
    if (scale > 0) setScale((prevScale) => prevScale - 0.05);
  });

  useEffect(() => {
    if (scale <= 0) {
      onRemoved();
    }
  }, [scale, onRemoved]);

  const getRandomColor = () => 0x1000000 + Math.random() * 0xffffff;

  useEffect(() => {
    setColors({
      fillColor1: getRandomColor(),
      stroke: getRandomColor()
    });
  }, []);

  const draw = React.useCallback(
    (g: PixiGraphics) => {
      g.clear();
      g.lineStyle(5, colors.stroke);
      g.beginFill(colors.fillColor1, 0.5);

      switch (shapeType) {
        case 0:
          g.drawCircle(0, 0, 100);
          break;
        case 1:
          g.drawRect(-50, -25, 100, 50);
          break;
        case 2:
          g.drawRect(-50, -50, 100, 100);
          break;
        case 3:
          g.drawRoundedRect(-50, -25, 100, 50, 3);
          break;
        case 4:
          g.drawRoundedRect(-50, -50, 100, 100, 3);
          break;
        case 5:
          g.drawEllipse(-50, -25, 100, 50);
          break;
      }

      g.endFill();
    },
    [colors.fillColor1, colors.stroke, shapeType]
  );

  return (
    <Graphics
      draw={draw}
      anchor={0.5}
      x={position.x}
      y={position.y}
      rotation={rotation}
      scale={scale}
    />
  );
};