import React, { StrictMode, useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useOnKeyDown, useMouseEvent } from "./hooks";
import { getRandomInt } from "./helpers";
import { SmashChar, SmashCursor, SmashShape, SmashTail } from "./models";
import { Char, Cursor, CursorTail, Image } from "./components";
import { useApp, Stage } from "@saitonakamura/react-pixi";
import "./styles.css";

const rootElement = document.getElementById("root");

const RenderArea = () => {
  const app = useApp();

  useEffect(() => {
    const loader = app.loader;

    loader.baseUrl = "/assets";

    loader.add("graphics/sprites/animals/1.png");
    loader.add("graphics/sprites/animals/2.png");
    loader.add("graphics/sprites/animals/3.png");
    loader.add("graphics/sprites/animals/4.png");
    loader.add("graphics/sprites/animals/5.png");
    loader.add("graphics/sprites/animals/6.png");
    loader.add("graphics/sprites/animals/7.png");
    loader.add("graphics/sprites/animals/8.png");
    loader.add("graphics/sprites/animals/9.png");

    loader.add("sounds/1.mp3");
    loader.add("sounds/2.mp3");
    loader.add("sounds/3.mp3");
    loader.add("sounds/4.mp3");
    loader.add("sounds/5.mp3");
    loader.add("sounds/6.mp3");
    loader.add("sounds/7.mp3");
    loader.add("sounds/8.mp3");
    loader.add("sounds/9.mp3");
    loader.add("sounds/10.mp3");
    loader.add("sounds/11.mp3");
    loader.add("sounds/12.mp3");
    loader.add("sounds/13.mp3");
    loader.add("sounds/14.mp3");
    loader.add("sounds/15.mp3");

    loader.add("sounds/key_1.mp3");
    loader.add("sounds/key_2.mp3");

    loader.load((_, resources) => {
      console.log('Resources loaded', resources);
    });
  }, [app]);

  useEffect(() => app.resize());

  useEffect(() => {
    window.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  });

  const pressedKey = useOnKeyDown();
  const mouseEvent = useMouseEvent(false);
  const mouseMove = useMouseEvent(true);
  const [chars, setChars] = useState<SmashChar[]>([]);
  const [shapes, setShapes] = useState<SmashShape[]>([]);
  const [cursor, setCursor] = useState<SmashCursor>();
  const [cursorTail, setCursorTail] = useState<SmashTail>({ id: 'cursorTail', positions: []});
  const uniqueSymbolId = useRef(0);

  const onCharRemoved = (id: string) => {
    setChars((prevChars) => prevChars.filter((x) => x.id !== id));
  };

  const onShapeRemoved = (id: string) => {
    setShapes((prevShapes) => prevShapes.filter((x) => x.id !== id));
  };

  useEffect(() => {
    if (pressedKey !== null) {
      setChars([
        ...chars,
        {
          id: "id_" + uniqueSymbolId.current++,
          char: pressedKey,
          position: {
            x: getRandomInt(0, app.view.clientWidth),
            y: getRandomInt(0, app.view.clientHeight)
          }
        }
      ]);
    }
    // eslint-disable-next-line
  }, [app, pressedKey]);

  useEffect(() => {
    if (mouseEvent === undefined)
      return;

    if (mouseEvent.isButtonDown) {
      setShapes([
        ...shapes,
        {
          id: "id_" + uniqueSymbolId.current++,
          position: {
            x: mouseEvent.x,
            y: mouseEvent.y
          }
        }
      ]);
    }
    // eslint-disable-next-line
  }, [mouseEvent ]);

  useEffect(() => {
    if (mouseMove === undefined)
      return;

    setCursor({
      id: "cursor",
      position: { x: mouseMove.x, y: mouseMove.y }
    });

    setCursorTail((prevCursorTail) => ({
      id: "cursorTail",
      positions: [...prevCursorTail.positions.filter((_, i) => i > prevCursorTail.positions.length - 30), { x: mouseMove.x, y: mouseMove.y } ]
    }));
  }, [mouseMove]);

  return (
    <React.Fragment>
      {chars.map((char) => (
        <Char
          key={char.id}
          text={char.char}
          position={char.position}
          onRemoved={() => onCharRemoved(char.id)}
        />
      ))}

      {shapes.map((shape) => (
        <Image
          key={shape.id}
          position={shape.position}
          onRemoved={() => onShapeRemoved(shape.id)}
        />
      ))}

      <CursorTail key={cursorTail.id} positions={cursorTail.positions} />

      {cursor ? <Cursor key={cursor.id} position={cursor.position} /> : null}
    </React.Fragment>
  );
};

const App = () => {
  return (
    <div>
      <Stage
        options={{
          forceCanvas: true,
          resizeTo: window,
          antialias: false
        }}
      >
        <RenderArea />
      </Stage>
    </div>
  );
};

const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
