import { useEffect, useRef, useState } from "react";

export function useOnKeyDown() {
  // State for keeping track of whether key is pressed
  const [key, setKey] = useState<string | null>(null);
  // If pressed key is our target key then set to true
  function downHandler({ key }: KeyboardEvent) {
    setKey(key);
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    setKey(null);
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return key;
}

export const useMouseEvent = (includeMouseMove: boolean) => {
  const isButtonDown = useRef(false);
  const [event, setEvent] = useState<{isButtonDown: boolean, x: number, y: number}>();

  const setFromEvent = (e: PointerEvent) => {
    setEvent({
      isButtonDown: isButtonDown.current,
      x: e.clientX,
      y: e.clientY
    });
  };

  const buttonDown = (e: PointerEvent) => {
    isButtonDown.current = true;

    setEvent({
      isButtonDown: isButtonDown.current,
      x: e.clientX,
      y: e.clientY
    });
  };

  const buttonUp = (e: PointerEvent) => {
    isButtonDown.current = false;

    setEvent({
      isButtonDown: isButtonDown.current,
      x: e.clientX,
      y: e.clientY
    });
  };

  useEffect(() => {
    if (includeMouseMove) window.addEventListener("pointermove", setFromEvent);

    window.addEventListener("pointerdown", buttonDown);
    window.addEventListener("pointerup", buttonUp);

    return () => {
      if (includeMouseMove)
        window.removeEventListener("pointermove", setFromEvent);

      window.removeEventListener("pointerdown", buttonDown);
      window.removeEventListener("pointerup", buttonUp);
    };
  }, [includeMouseMove]);

  return event;
};
